<template>
  <div class="pre">
    <!-- <van-notice-bar
      style="position:fixed;width:100%;top:0;"
      left-icon="volume-o"
      text="注意：IC卡用户，请前往柜台充值、刷卡！"
    /> -->
    <div v-if="isBind">
      <div
        class="pre-page"
        v-for="(item, index) in dataInfo"
        :key="index"
        :class="index % 3 === 0 ? 'pre-brilliant' : index % 3 === 1 ? 'pre-dark' : 'pre-grey'"
      >
        <div class="pre-page-name">
          <span>{{ item.wuserName }}「{{ item.wuserNo }}-{{ item.wuserAddress }}」</span>
        </div>
        <div class="pre-page-balance">
          <span>余额（元）</span>
        </div>
        <div class="pre-page-number">
          <span>{{ item.depositAmount }}</span>
          <!-- <van-button @click="gotoRecord(item)" class="par-button">缴费记录</van-button> -->
          <!--          <div v-if="item.allowCharge" v-html="item.html"></div>-->
          <!--          <van-button v-else @click="showNotify(item)" class="par-button par-button-disabled">充值缴费</van-button>-->
        </div>
        <van-divider class="pre-page-divider" />
        <div class="pre-page-choose">
          <span>请选择充值金额</span>
        </div>
        <div class="pre-page-par">
          <van-button class="par-button" @click="handleProSum(index, 100)">100元</van-button>
          <van-button class="par-button" @click="handleProSum(index, 200)">200元</van-button>
          <van-button class="par-button" @click="handleProSum(index, 500)">500元</van-button>
          <van-button class="par-button" @click="handleProSum(index, 'other')">其他</van-button>
        </div>
      </div>
    </div>

    <no-Data v-else />

    <!-- 弹出框 -->
    <div>
      <van-popup
        class="pre-sum"
        v-model="proSumShow"
        closeable
        close-icon-position="top-left"
        position="bottom"
      >
        <div class="pre-sum-money">
          <span>充值缴费</span>
          <van-field
            class="psm-field"
            v-model="preSumMoney"
            placeholder="请输入金额"
            :error-message="errorMesg"
          />
          <van-button
            class="psm-buttom"
            type="info"
            @click="handleMoneyCost"
            :disabled="preSumMoney.length <= 0"
          >
            确定
          </van-button>
        </div>
        <van-number-keyboard v-model="preSumMoney" :show="true" extra-key="." :maxlength="10" />
      </van-popup>
    </div>
    <van-overlay :show="overlayShow">
      <div class="overlay-wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Toast, Notify } from "vant";
import { selectwuser, paymentRecharge, selectOneWUser, jsApiPay } from "../../api/home";
import NoData from "@/components/noData.vue";
import store from "@/store";
import NP from 'number-precision';
export default {
  components: {
    NoData
  },
  data() {
    return {
      overlayShow: false,
      proSumShow: false,
      preSumMoney: 0,
      errorMesg: "",
      currentIndex: "",
      dataInfo: [],
      isBind: store.getters.homeInfo.isBind
    };
  },
  mounted() {
    this.initData();
    console.log("userInfo", store.getters);
  },
  methods: {
    showNotify(item) {
      Notify({ type: "warning", message: `${item.reason}` });
    },
    async initData(flag) {
      if (store.getters.homeInfo.isBind) {
        if (
          this.$route.query &&
          this.$route.query.wuserNo &&
          this.$route.query.wuserNo.length > 0
        ) {
          console.log("AA---", this.$route.query.wuserNo);
          await this.selectOneWUser();
        } else {
          console.log("bbb---");
          await this.selectwuser();
        }
      }
    },
    async selectwuser() {
      const { status, resultData } = await selectwuser();
      if (status === "complete") {
        this.dataInfo = resultData.map((item, index) => {
          let path =
            "main/pages/nativeindex/nativeindex.html?fr=p3&pg=charge&ct=371300&sv=1&ch=010026643&bk=" +
            item.wuserNo;
          let script = document.createElement("script");
          script.type = "text/wxtag-template";
          script.text =
            "<button class=\"test-btn-charge\">充值缴费</button><style>.test-btn-charge {width: 100%;height: 100%;background: rgba(0, 0, 0, 0);color: #fff;font-size: 14px;border: none;padding: 0 7px;line-height: 30px;z-index: 3;outline: none;border:1px solid rgba(255, 255, 255, 0.5);border-radius:8px}</style>";
          let html = `<wx-open-launch-weapp id="launch-btn-${new Date().valueOf() +
          "-" +
          index}" username="gh_aceb9bd462ab" path="${path}">${
            script.outerHTML
          }</wx-open-launch-weapp>`;
          return {
            ...item,
            html
          };
        });
        console.log(this.dataInfo);
      }
    },
    async selectOneWUser() {
      let arg = this.$route.query.wuserNo;
      const { status, resultData } = await selectOneWUser(arg);
      if (status === "complete") {
        this.dataInfo = resultData.map((item, index) => {
          let path =
            "main/pages/nativeindex/nativeindex.html?fr=p3&pg=charge&ct=371300&sv=1&ch=010026643&bk=" +
            item.wuserNo;
          let script = document.createElement("script");
          script.type = "text/wxtag-template";
          script.text =
            "<button class=\"test-btn-charge\">充值缴费</button><style>.test-btn-charge {width: 100%;height: 100%;background: rgba(0, 0, 0, 0);color: #fff;font-size: 14px;border: none;padding: 0 7px;line-height: 30px;z-index: 3;outline: none;border:1px solid rgba(255, 255, 255, 0.5);border-radius:8px}</style>";
          let html = `<wx-open-launch-weapp id="launch-btn-${new Date().valueOf() +
          "-" +
          index}" username="gh_aceb9bd462ab" path="${path}">${
            script.outerHTML
          }</wx-open-launch-weapp>`;
          return {
            ...item,
            html
          };
        });
      }
    },
    // 充值事件
    async handleProSum(index, value) {
      if (value === "other") {
        this.preSumMoney = "";
        this.errorMesg = "";
        this.proSumShow = true;
        this.currentIndex = index;
      } else {
        // 如果为欠费，充值金额需大于欠费金额
        if (this.dataInfo[index].depositAmount < 0 && value < Math.abs(this.dataInfo[index].depositAmount)) {
          return this.showNotify({
            reason: "充值金额需大于欠费金额"
          });
        }
        let arg = {
          clientCode: this.dataInfo[index].wuserNo,
          // 支付金额单位为分，需要换算 *100
          amount: NP.times(value,100),
          openId: this.$store.getters.userInfo.openId
        };
        console.log(value);
        let resultData = await jsApiPay(arg);
        console.log("下单数据", resultData);
        if (Object.keys(resultData).length === 0) {
          if (typeof WeixinJSBridge === "undefined") {
            if (document.addEventListener) {
              document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
              document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
            }
          }
        } else {
          this.onBridgeReady(resultData);
        }
      }
    },
    // 微信支付：
    onBridgeReady(params) {
      var that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.package, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign //支付签名
        },
        function(res) {
          console.log("调用支付后返回", res);
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            console.log("成功", res.err_msg);
            that.$router.push({
              name: "PaymentResult",
              path: "/QueryPay/PaymentResult",
              query: {
                name: "RechargeRecord"
              }
            });
            // that.$router.push({
            //     name: 'RechargeRecord',
            //     path: '/WaterAccount/RechargeRecord',
            //     query: {
            //         wuserNo: this.dataInfo[this.currentIndex].wuserNo
            //     }
            // })
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            console.log("失败", res.err_msg);
          }
        }
      );
    },
    handleMoneyCost() {
      this.errorMesg = "";
      const num = Number(this.preSumMoney);
      if (!/^\d+(\.\d{1,2})?$/.test(this.preSumMoney)) {
          // 只能输入正整数或者两位小数
        this.errorMesg = "输入金额需只能是正整数或者两位小数";
      }else if(num >= 0.01){
        this.proSumShow = false;
        this.handleProSum(this.currentIndex, this.preSumMoney);
      } else {
        this.errorMesg = "输入金额需大于 0.01";
      }
    },
    // 缴费记录
    gotoRecord(data) {
      this.$router.push({
        name: "RechargeRecord",
        path: "/WaterAccount/RechargeRecord",
        query: {
          wuserNo: data.wuserNo
        }
      });
    },
    gotoCharge(data) {
      let url = `https://payapp.weixin.qq.com/life/index?fr=p3&pg=charge&ct=371300&sv=1&ch=010026643&bk=${data.wuserNo}`;
      window.location.href = url;
    }
  }
};
</script>

<style lang="less" scoped>
.pre {
  .pre-brilliant {
    background: url('../../assets/background/pre-brilliant.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .pre-dark {
    background: url('../../assets/background/pre-dark.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .pre-grey {
    background: url('../../assets/background/pre-grey.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &-page {
    display: flex;
    flex-direction: column;
    margin: 16px;
    border-radius: 8px;
    overflow: hidden;

    &-name {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 16px 0 16px;

      span {
        padding-bottom: 8px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
      }

      span:first-child {
        margin-right: 8px;
      }
    }

    &-address {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      padding: 0 16px 10px 16px;
      line-height: 22px;
      max-height: 60px;

      em {
        font-style: normal;
        border: 1px solid #ffffff;
        padding: 1px 5px;
        border-radius: 4px;
        font-size: 12px;
        margin-right: 8px;
      }
    }

    &-balance {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      padding: 0 16px 0px 16px;
    }

    &-number {
      font-family: PingFangSC-Medium;
      font-size: 28px;
      color: #ffffff;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;

      em {
        font-size: 14px;
        font-style: normal;
        margin-top: 15px;
      }

      .par-button {
        padding: 0 6px;
        height: 32px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        background: transparent;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #ffffff;

        &-disabled {
          cursor: not-allowed;
          opacity: .5;
        }
      }
    }

    &-divider {
      margin: 6px 0;
    }

    &-choose {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: hsla(0, 0%, 100%, 0.65);
      padding: 0px 16px;
    }

    &-par {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 16px 20px 16px;
      margin-top: 10px;

      .par-button {
        height: 32px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        background: transparent;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }

  .pre-sum {
    .pre-sum-money {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;

      .psm-field {
        margin-top: 16px;
        width: 70%;
        border: 1px solid #ebedf0;
        border-radius: 8px;
        font-family: PingFangSC-Medium;
        font-size: 20px;
      }

      .psm-buttom {
        margin: 20px 0;
        width: 70%;
        border-radius: 8px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
      }
    }

    .van-number-keyboard {
      position: static;
    }
  }

  .overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: '#ffffff';
  }

  /deep/ .van-divider {
    border-color: rgba(255, 255, 255, 0.14) !important;
  }
}
</style>
